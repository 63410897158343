import React from 'react';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import TheGablesHomepageLoopVideo from '../../components/TheGablesHomepageLoopVideo/TheGablesHomepageLoopVideo';
import { useTheGablesInfo } from '../../content/projects/the-gables.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageTheGables.scss';

type ProjectPageTheGablesProps = {}

const ProjectPageTheGables: PageComponent<ProjectPageTheGablesProps> = props => {
  const metaInfo = useTheGablesInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <TheGablesHomepageLoopVideo />
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides range={[1, Infinity]}/>
  </ProjectPage>
}

export default ProjectPageTheGables;