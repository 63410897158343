import { Observer } from 'mobx-react-lite';
import React from 'react';
import videoPoster from '../../content/images/the-gables/the-gables-homepage-loop-video-poster.jpg';
// import './TheGablesHomepageLoopVideo.scss';
import videoMp4 from '../../content/images/the-gables/the-gables-homepage-loop-video.mp4';
import videoWebm from '../../content/images/the-gables/the-gables-homepage-loop-video.webm';
import NativeVideoWrapper from '../NativeVideoWrapper/NativeVideoWrapper';

type TheGablesHomepageLoopVideoProps = {
  rounded?: boolean,
}

const TheGablesHomepageLoopVideo: React.FC<TheGablesHomepageLoopVideoProps> = props => {
  return <Observer children={() => (
    <NativeVideoWrapper 
      id="TheGablesHomepageLoopVideo"
      className="TheGablesHomepageLoopVideo" 
      src={[videoWebm, videoMp4]}
      poster={videoPoster}
      width={1280}
      height={720}
      onlyLoadWhenVisible
      rounded={props.rounded}
    />
  )} />
}

export default TheGablesHomepageLoopVideo;