import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { TheGablesImagesQuery } from "../../types/generated";

export const ProjectMetaInfoTheGables: ProjectMetaInfo = {
  name: "the-gables",
  displayName: "The Gables B&B",
  abbreviation: "The Gables",
  subtitle: "Branding & Website for The Gables B&B",
  description: "The Gables is a charming historic residence in Picton that was converted to a Bed & Breakfast, located in the scenic town of Picton in the South Island of New Zealand.",
  url: 'https://thegables.co.nz/',
  categories: [
    Category.websites,
    Category.design,
  ],
  sectors: [
    Sector.tourism,
  ],
  hasPage: true,
  hashtags: [
    Hashtag.branding,
  ],
  keywords: [
    'web design',
    'web development',
    'branding design',
    'WordPress',
  ],
  // logos: {
  //   default: TheGablesLogoDefault,
  //   reversed: TheGablesLogoReversed,
  // }
}
export const useTheGablesInfo = () => {
  const TheGablesImages: TheGablesImagesQuery = useStaticQuery(graphql`
    query TheGablesImages {
      featuredImage: file(relativePath: {eq: "images/the-gables/the-gables-bnb-lounge-dining-table-view.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/the-gables/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "The Gables B&B Homepage Screenshot",
      alt: "The Gables Homepage Screenshot, desktop version",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoTheGables,
    featuredImage: {
      image: TheGablesImages.featuredImage!.childImageSharp,
      title: "The Gables B&B Lounge View",
      alt: "The Gables B&B lounge view from the dining table",
    },
    gallery: TheGablesImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}